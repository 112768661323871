import { Loading } from "./Spinner";

export default function Button({
  children,
  type = "button",
  className,
  onClick,
  disabled,
  isLoading,
}: {
  children: React.ReactNode;
  type?: "submit" | "button";
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}) {
  return (
    <button
      className={`${className} w-full p-[1.125rem] bg-primary text-[#FCFCFC] text-[1.125rem] font-[500] rounded-[30px] disabled:bg-[#E0E0E0] disabled:text-[#BDBDBD] disabled:cursor-not-allowed hover:bg-[#C83E1E] transition-all duration-200 ease-in-out flex justify-center`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {isLoading ? <Loading /> : children}
    </button>
  );
}
