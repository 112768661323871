import Logo from "../assets/logo.svg";
import Users from "../assets/users.svg";
import User from "../assets/admin-avatar.svg";
import SignoutIcon from "../assets/signout.svg";

export default function DashboardLayout({ children }: any) {
  return (
    <div className="flex">
      <div className="w-[20rem] bg-primary h-screen relative">
        <img src={Logo} alt="logo" className="mx-auto my-[2.5rem]" />
        <div className="border-t-solid border-t-[1px] border-t-[white]/20"></div>
        <div className="text-[white] m-4">
          <button className="flex items-center gap-2 w-full p-[1.25rem] bg-[#8D4544] rounded-[12px]">
            <img src={Users} alt="users" className="w-[1.5rem] h-[1.5rem]" />
            Manage users
          </button>
        </div>
        <div className="p-4 flex gap-3 absolute bottom-4 rounded-[12px] border-solid border-[1px] border-[#885050] left-4 right-4 w-[calc(100%-2rem)] bg-[#682222]">
          <img
            src={User}
            alt="user"
            className="w-[3.5rem] h-[3.5rem] rounded-full"
          />
          <div className="grid content-center">
            <span className="text-[1rem] font-[600] text-[white]">Admin</span>
            <button
              className="flex items-center gap-2 hover:opacity-[0.8] transition-all duration-200"
              onClick={() => {
                localStorage.removeItem("token");
                window.location.href = "/";
              }}
            >
              <img src={SignoutIcon} alt="signout" />
              <span className="text-[#FF9F9F] font-[500] text-[0.875rem]">
                Logout
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="p-[1.75rem] w-full h-screen overflow-auto">
        {children}
      </div>
    </div>
  );
}
