import { useInfiniteQuery } from "@tanstack/react-query";
import Button from "../../components/Button";
import DashboardLayout from "../../layouts/DashboardLayout";
import Chevron from "../../assets/chevron.svg";
import SearchIcon from "../../assets/search.svg";
import { useEffect, useRef, useState } from "react";
import AddUserModal from "./AddUser";
import apis from "../../helpers/apis/apis";
import { DataRowSkeleton } from "./DataRowSkeleton";
import { DataRow } from "./DataRow";
import { RemovedUsersModal } from "./RemovedUsersModal";
import { DeleteModal } from "./DeleteModal";

export default function Dashboard() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletingUser, setDeletingUser] = useState(null); // [1]
  const [selectedUser, setSelectedUser] = useState(null); // [1]
  const [removedUsersModalOpen, setRemovedUsersModalOpen] = useState(false); // [1
  const [search, setSearch] = useState("");
  const { data, isLoading, isError, refetch, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ["users", search, removedUsersModalOpen],
      queryFn: ({ pageParam = 0 }) => apis.getUsers(pageParam, search, true),
      getNextPageParam: (lastPage, pages) =>
        lastPage.data.users.length === 10 ? pages?.length : undefined,
      initialPageParam: 0, // Add this line
    });
  const loadMoreRef = useRef(null);

  const handleLoadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          // users.length < total &&
          !isLoading
        ) {
          handleLoadMore();
        }
      },
      { threshold: 0.1 }
    );
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [isLoading, handleLoadMore]);

  return (
    <DashboardLayout>
      <RemovedUsersModal
        isOpen={removedUsersModalOpen}
        handleModal={() => setRemovedUsersModalOpen(!removedUsersModalOpen)}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        handleModal={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        deletingUser={deletingUser}
        refetch={refetch}
      />
      <AddUserModal
        isOpen={isOpen}
        handleModal={() => {
          setSelectedUser(null);
          setIsOpen(!isOpen);
        }}
        refetch={refetch}
        editUser={selectedUser}
      />
      <div className="flex justify-between w-full items-center">
        <h1 className="text-[2rem] font-[700] leading-[120%]">
          Manage users ({data?.pages[0]?.data?.totalUsers})
        </h1>
        <div className="h-fit">
          <Button
            className="py-[1rem] px-[1.75rem] text-[1.125rem] font-[500]"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Add new user
          </Button>
        </div>
      </div>
      <div
        className="bg-[white] w-full p-4 mt-4 shadow-backdrop rounded-[16px] overflow-auto h-[calc(100vh-10rem)]"
        id="content"
      >
        <div className="flex justify-between">
          <div className="relative">
            <img
              src={SearchIcon}
              alt="search"
              className="absolute top-[50%] left-4 transform -translate-y-1/2 w-[1.5rem] h-[1.5rem]"
            />
            <input
              className="border-solid border-[1px] border-lightGrayBlueTint rounded-[8px] p-4 text-[1rem] md:min-w-[320px] min-w-full pl-12 focus:outline-none focus:border-[#C83E1E]"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <button
            className="flex items-center"
            onClick={() => setRemovedUsersModalOpen(true)}
          >
            Removed users
            <img src={Chevron} alt="chevron" />
          </button>
        </div>
        <div className="py-4 px-6 bg-[#F4EBEB] rounded-[6px] mt-6 w-full flex gap-4">
          <span className="w-1/5 text-[0.875rem]">Name</span>
          <span className="w-1/3 text-[0.875rem]">Email address</span>
          <span className="w-1/6 text-[0.875rem]">Joined on</span>
          <span className="w-1/5 text-[0.875rem]">Last activity</span>
          <span className="w-1/5 text-[0.875rem]">Action</span>
        </div>

        {isLoading && (
          <>
            <DataRowSkeleton />
            <DataRowSkeleton />
            <DataRowSkeleton />
            <DataRowSkeleton />
            <DataRowSkeleton />
          </>
        )}
        {data?.pages?.map((page: any) =>
          page.data?.users?.map((user: any) => (
            <DataRow
              key={user.id}
              user={user}
              setIsDeleteModalOpen={setIsDeleteModalOpen}
              setDeletingUser={setDeletingUser}
              setEditModalOpen={setIsOpen}
              setSelectedUser={setSelectedUser}
            />
          ))
        )}

        {hasNextPage && (
          <button
            onClick={handleLoadMore}
            ref={loadMoreRef}
            className="w-full text-[#6A6A6A] text-[1rem] font-[500] mt-3 cursor-pointer"
          >
            <DataRowSkeleton />
            {/* <Spinner /> */}
          </button>
        )}
      </div>
    </DashboardLayout>
  );
}
