import { useState } from "react";
import ShowPassword from "../assets/eye-slash.svg";
import HidePassword from "../assets/eye.svg";

export default function Input({
  id,
  label,
  type,
  placeholder,
  value,
  onChange,
  error,
  errorMessage,
  ...props
}: {
  id: string;
  label: string;
  type: string;
  placeholder: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  errorMessage?: string;
  [key: string]: any;
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <label htmlFor={id} className="text-[0.875rem] font-[400] text-[#333333]">
        {label}
      </label>
      {type === "password" ? (
        <div className="flex justify-between items-center mt-2 relative">
          <input
            id={id}
            type={showPassword ? "text" : "password"}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={`w-full pr-[2.5rem] p-[1.125rem] border rounded-[8px] focus:outline-none focus:border-[#C83E1E] ${
              error ? "border-[red]/50" : "border-lightGrayBlueTint"
            }`}
            {...props}
          />
          <button
            className="text-[0.875rem] font-[400] text-[#333333] absolute
            right-0 top-0 bottom-0 p-4"
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <img
                src={ShowPassword}
                alt="Show password"
                className="w-[1.25rem] h-[1.25rem]"
              />
            ) : (
              <img
                src={HidePassword}
                alt="Hide password"
                className="w-[1.25rem] h-[1.25rem]"
              />
            )}
          </button>
        </div>
      ) : (
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={`w-full p-[1.125rem] mt-2 border rounded-[8px] focus:outline-none focus:border-[#C83E1E] ${
            error ? "border-[red]/50" : "border-lightGrayBlueTint"
          }`}
          {...props}
        />
      )}
      {error && (
        <p className="text-xs text-red-500 mt-1 text-[red]/70">
          {errorMessage}
        </p>
      )}
    </div>
  );
}
