import Button from "../../components/Button";
import apis from "../../helpers/apis/apis";
import CloseIcon from "../../assets/close.svg";
import toast from "react-hot-toast";

export function DeleteModal({
  isOpen,
  handleModal,
  deletingUser,
  refetch,
}: {
  isOpen: boolean;
  handleModal: () => void;
  deletingUser: any;
  refetch: () => void;
}) {
  const handleDelete = () => {
    const promise = apis.deleteUser(deletingUser.id);
    toast.promise(promise, {
      loading: "Deleting user...",
      success: () => {
        handleModal();
        refetch();
        return "User deleted";
      },
      error: "Failed to delete user",
    });
  };
  return (
    <>
      <div
        className="fixed z-[50] overflow-auto top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] max-h-[90%] w-[570px]"
        style={{
          display: isOpen ? "block" : "none",
        }}
      >
        <div className="rounded-[16px] bg-[white] p-[1.75rem] z-[51]">
          <div className="flex justify-between mb-[1.5rem]">
            <h3 className="text-[1.75rem] font-[700]">
              Delete {deletingUser?.firstName} {deletingUser?.lastName}
            </h3>
            <button onClick={handleModal}>
              <img src={CloseIcon} alt="close" />
            </button>
          </div>
          <div className="grid gap-[1.875rem]">
            <p
              className="
                text-[#333333] text-[1.125rem] font-[400] text-center
              "
            >
              Are you sure you want to remove {deletingUser?.firstName}{" "}
              {deletingUser?.lastName}? This action can be undone.
            </p>
            <div className="flex gap-4">
              <button
                className="w-full p-[1.125rem] border-primary border-solid border-[1px] text-primary text-[1.125rem] font-[500] rounded-[30px] hover:border-[#C83E1E] hover:text-[#C83E1E] transition-all duration-200"
                onClick={handleModal}
              >
                Cancel
              </button>
              <Button onClick={handleDelete}>Delete</Button>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full z-[49] h-[100vh] opacity-[0.3] bg-[black]"
          onClick={handleModal}
        />
      )}
    </>
  );
}
