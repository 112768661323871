import React, { createContext, useContext, useMemo } from "react";
import apis from "../apis/apis";
import toast from "react-hot-toast";
const AuthContext = createContext<any>("default value");

// Create a provider component
export function AuthProvider(props: { children: React.ReactNode }) {
  const getToken = () => {
    return localStorage.getItem("token");
  };
  const login = (email: string, password: string) => {
    apis
      .login({ email, password })
      .then((res) => {
        localStorage.setItem("token", res.accessToken);
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        toast.error("Invalid email or password");
      });
  };

  const value = useMemo(
    () => ({
      getToken,
      login,
    }),
    []
  );

  return <AuthContext.Provider value={value} {...props} />;
}

// Custom hook to use the context
export function useAuthContext() {
  return useContext(AuthContext);
}
