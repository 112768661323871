import DefaultUserIcon from "../../assets/user.svg";
import { ImageView } from "../../components/ImageView";
import apis from "../../helpers/apis/apis";
import moment from "moment";
import toast from "react-hot-toast";

export function DataRow({
  user,
  setIsDeleteModalOpen,
  setDeletingUser,
  setEditModalOpen,
  addBack,
  refetch,
  setSelectedUser,
}: {
  user: any;
  setIsDeleteModalOpen?: (value: boolean) => void;
  setDeletingUser?: (value: any) => void;
  setEditModalOpen?: (value: boolean) => void;
  addBack?: boolean;
  refetch?: () => void;
  setSelectedUser?: (value: any) => void;
}) {
  return (
    <div className="py-4 px-6 rounded-[6px] w-full flex items-center gap-4 border-b-[#E6E6E6] border-b-solid border-b-[1px]">
      <div className="w-1/5 flex items-center gap-2 overflow-hidden">
        <ImageView
          src={user.profileImg || DefaultUserIcon}
          alt="user"
          className="w-[2.5rem] h-[2.5rem] rounded-full"
        />
        <span className="text-[0.875rem]">
          {(user.firstName + " " + user.lastName).length > 16
            ? (user.firstName + " " + user.lastName).slice(0, 16) + "..."
            : user.firstName + " " + user.lastName}
        </span>
      </div>
      <div className="w-1/3 overflow-hidden text-[0.875rem]">{user.email}</div>
      <div className="w-1/6 overflow-hidden text-[0.875rem]">
        {moment(user.joinedOn).format("MMM D, YYYY")}
        {/* Mar 20, 2023 */}
      </div>
      <div className="w-1/5 overflow-hidden text-[0.875rem]">
        {/* Dec 9, 2023 | 9:30PM */}
        {user.lastActive
          ? moment(user.lastActive).format("MMM D, YYYY | h:mma")
          : "N/A"}
      </div>
      <div className="w-1/5 overflow-hidden gap-[1.5rem] flex">
        {/* <button
            className="text-[0.875rem] text-[#433AE0]"
            onClick={() => {
              setEditModalOpen && setEditModalOpen(true);
              setSelectedUser && setSelectedUser(user);
            }}
          >
            Edit
          </button> */}
        <button
          className="text-[0.875rem] text-[#E20000] hover:opacity-[0.7] transition-all duration-200"
          style={{
            color: addBack ? "#433AE0" : "#E20000",
          }}
          onClick={() => {
            if (addBack) {
              const promise = apis.addBackUser(user.id);
              toast.promise(promise, {
                loading: "Adding user back...",
                success: () => {
                  setDeletingUser && setDeletingUser(null);
                  refetch && refetch();
                  return "User added back";
                },
                error: "Failed to add user back",
              });
            } else {
              setIsDeleteModalOpen && setIsDeleteModalOpen(true);
              refetch && refetch();
              setDeletingUser && setDeletingUser(user);
            }
          }}
        >
          {addBack ? "Add back" : "Remove"}
        </button>
      </div>
    </div>
  );
}
