/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import client from "./client";

export default {
  login: ({ email, password }: { email: string; password: string }) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}auth/admin/login`, {
        email,
        password,
      })
      .then((res: any) => res.data),
  getUsers: (page: number, searchKeyword?: string, showActive?: boolean) =>
    client.get("/users", { params: { page, searchKeyword, showActive } }),
  addUser: (data: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => client.post("/auth/register", data),
  signedUrl: (data: { fileName: string; fileType: string }) =>
    client.get("/signed-url", { params: data }),
  uploadFile: (userId: string, mimeType: string) =>
    client.get(`/users/${userId}/images/signed-url`, {
      params: { uploadType: "USER_PROFILE", mimeType: mimeType },
    }),
  editUser: (
    data: {
      email: string;
      password: string;
      firstName: string;
      lastName: string;
      profileImg: string;
    },
    id: string
  ) => client.put(`/users/${id}`, data),
  deleteUser: (id: string) => client.delete(`/users/${id}`),
  addBackUser: (id: string) =>
    client.patch(`/users/${id}/status`, { isActive: true }),
  getPredesignedUrl: ({
    id,
    mimeType,
    uploadType,
  }: {
    id: string;
    mimeType: string;
    uploadType: string;
  }) => {
    let url = `/users/${id}/images/signed-url?mimeType=${mimeType}&uploadType=${uploadType}`;

    return client.get(url).then((r) => r.data);
  },
};
