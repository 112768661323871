import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./modules/login/Login";
import { AuthProvider } from "./helpers/contexts/AuthContext";
import { Toaster } from "react-hot-toast";
import { ProtectedRoute } from "./helpers/ProtectedRoute";
import Dashboard from "./modules/dashboard/Dashboard";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const queryClient = new QueryClient();
  const isMobileOrTablet = window.innerWidth < 1024;
  if (isMobileOrTablet) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h1 className="text-3xl font-bold text-center">
          This application is not supported on mobile or tablet devices.
        </h1>
      </div>
    );
  }
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Toaster
          toastOptions={{
            style: {
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            },
            success: {
              style: {
                background: "#0C984D",
                color: "white",
              },
            },
          }}
        />
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
