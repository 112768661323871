import { useInfiniteQuery } from "@tanstack/react-query";
import SearchIcon from "../../assets/search.svg";
import { useEffect, useRef, useState } from "react";
import apis from "../../helpers/apis/apis";
import CloseIconWhite from "../../assets/close-white.svg";
import { DataRowSkeleton } from "./DataRowSkeleton";
import { DataRow } from "./DataRow";

export function RemovedUsersModal({
  isOpen,
  handleModal,
}: {
  isOpen: boolean;
  handleModal: () => void;
}) {
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null); // [1]
  const loadMoreRef = useRef(null);
  const { data, isLoading, isError, refetch, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ["removed-users", search, isOpen],
      queryFn: ({ pageParam = 0 }) => apis.getUsers(pageParam, search, false),
      getNextPageParam: (lastPage, pages) =>
        lastPage.data.users.length === 10 ? pages?.length : undefined,
      initialPageParam: 0, // Add this line
    });

  const handleLoadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          // users.length < total &&
          !isLoading
        ) {
          handleLoadMore();
        }
      },
      { threshold: 0.1 }
    );
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [isLoading, handleLoadMore]);

  return (
    <>
      {isOpen && (
        <button
          onClick={handleModal}
          style={{
            position: "absolute",
            top: "1.5rem",
            right: "1.5rem",
            zIndex: 52,
          }}
        >
          <img src={CloseIconWhite} alt="close" />
        </button>
      )}
      <div
        className="
          fixed z-[50] overflow-auto left-0 bottom-0 h-[93%] w-full bg-[#F9F9F9] px-[10rem] p-[1.75rem] z-[51] shadow-backdrop
        "
        style={{
          display: isOpen ? "block" : "none",
        }}
      >
        <div className="flex justify-between w-full items-center" id="header">
          <h1 className="text-[2rem] font-[700] leading-[120%]">
            Removed users ({data?.pages[0]?.data?.totalUsers})
          </h1>
        </div>
        <div className="bg-[white] w-full p-4 mt-4 shadow-backdrop rounded-[16px] overflow-auto">
          <div className="flex justify-between">
            <div className="relative">
              <img
                src={SearchIcon}
                alt="search"
                className="absolute top-[50%] left-4 transform -translate-y-1/2 w-[1.5rem] h-[1.5rem]"
              />
              <input
                className="border-solid border-[1px] border-lightGrayBlueTint rounded-[8px] p-4 text-[1rem] md:min-w-[320px] min-w-full pl-12 focus:outline-none focus:border-[#C83E1E]"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="py-4 px-6 bg-[#F4EBEB] rounded-[6px] mt-6 w-full flex gap-4">
            <span className="w-1/5 text-[0.875rem]">Name</span>
            <span className="w-1/3 text-[0.875rem]">Email address</span>
            <span className="w-1/6 text-[0.875rem]">Joined on</span>
            <span className="w-1/5 text-[0.875rem]">Last activity</span>
            <span className="w-1/5 text-[0.875rem]">Action</span>
          </div>

          {data?.pages?.[0]?.data.length === 0 && (
            <div className="text-[#6A6A6A] text-[1rem] font-[500] mt-4 text-center">
              No removed users
            </div>
          )}

          {isLoading && (
            <>
              <DataRowSkeleton />
              <DataRowSkeleton />
              <DataRowSkeleton />
              <DataRowSkeleton />
              <DataRowSkeleton />
            </>
          )}
          {data?.pages?.map((page: any) =>
            page.data?.users?.map((user: any) => (
              <DataRow
                key={user.id}
                user={user}
                addBack
                // setIsDeleteModalOpen={setIsDeleteModalOpen}
                setDeletingUser={setSelectedUser}
                // setEditModalOpen={setIsOpen}
                refetch={refetch}
              />
            ))
          )}

          {hasNextPage && (
            <button
              onClick={handleLoadMore}
              ref={loadMoreRef}
              className="rounded-[12px] p-[1.25rem] w-full text-[#6A6A6A] text-[1rem] font-[500] mt-3 cursor-pointer"
            >
              Loading...
              {/* <Spinner /> */}
            </button>
          )}
        </div>
      </div>
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full z-[49] h-[100vh] opacity-[0.3] bg-[black]"
          onClick={handleModal}
        />
      )}
    </>
  );
}
