import axios from "axios";
import apis from "./apis";

export const fileUpload = async (
  file: File,
  id: string,
  uploadType: string
) => {
  const { accessUrl, uploadUrl } = await apis.getPredesignedUrl({
    id,
    mimeType: file.type,
    uploadType,
  });
  const response = await axios.put(uploadUrl, file, {
    headers: { "Content-Type": file.type },
  });
  return { response, url: accessUrl };
};
