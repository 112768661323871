import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "./contexts/AuthContext";

export function ProtectedRoute({ children }: any) {
  const { getToken } = useAuthContext();
  const location = useLocation();
  if (!getToken() || getToken() === null || getToken() === undefined) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return children;
}
