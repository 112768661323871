import { useEffect, useRef, useState } from "react";
import UserIcon from "../../assets/user.svg";
import CloseIcon from "../../assets/close.svg";
import toast from "react-hot-toast";
import apis from "../../helpers/apis/apis";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { ImageView } from "../../components/ImageView";
import { fileUpload } from "../../helpers/apis/file-upload";
import { ReactComponent as UploadIcon } from "../../assets/upload.svg";

export function getInitial(firstName: string, lastName: string) {
  // Akshay Kumar => AK
  // Akshay => A
  // Kumar => K
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  } else if (firstName) {
    return firstName[0];
  } else if (lastName) {
    return lastName[0];
  } else {
    return "";
  }
}

export default function Modal({
  isOpen,
  handleModal,
  refetch,
  editUser,
}: {
  isOpen: boolean;
  handleModal: () => void;
  refetch: () => void;
  editUser?: any;
}) {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    profileImg: "",
  });
  // validate form
  const [error, setError] = useState({
    firstName: {
      error: false,
      message: "",
    },
    lastName: {
      error: false,
      message: "",
    },
    email: {
      error: false,
      message: "",
    },
    password: {
      error: false,
      message: "",
    },
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [profileImg, setProfileImg] = useState<any>(null);
  const profileImgRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
    // validate form
    if (e.target.id === "firstName") {
      if (e.target.value === "") {
        setError({
          ...error,
          firstName: {
            error: true,
            message: "First name is required",
          },
        });
      } else {
        setError({
          ...error,
          firstName: {
            error: false,
            message: "",
          },
        });
      }
    }
    if (e.target.id === "lastName") {
      if (e.target.value === "") {
        setError({
          ...error,
          lastName: {
            error: true,
            message: "Last name is required",
          },
        });
      } else {
        setError({
          ...error,
          lastName: {
            error: false,
            message: "",
          },
        });
      }
    }
    if (e.target.id === "email") {
      if (e.target.value === "") {
        setError({
          ...error,
          email: {
            error: true,
            message: "Email is required",
          },
        });
      } else if (
        !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(e.target.value)
      ) {
        setError({
          ...error,
          email: {
            error: true,
            message: "Invalid email address",
          },
        });
      } else {
        setError({
          ...error,
          email: {
            error: false,
            message: "",
          },
        });
      }
    }
    if (e.target.id === "password" && !editUser) {
      if (e.target.value === "") {
        setError({
          ...error,
          password: {
            error: true,
            message: "Password is required",
          },
        });
      } else {
        setError({
          ...error,
          password: {
            error: false,
            message: "",
          },
        });
      }
    }
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      // const promise = apis.addUser(form);
      let payload: any = { ...form };
      toast.loading(editUser ? "Updating user..." : "Creating user...");
      const promise = editUser
        ? apis.editUser(
            {
              ...form,
              profileImg: editUser.profileImg,
            },
            editUser.id
          )
        : apis.addUser(payload);

      const response = await promise;
      const user = response?.data;
      if (user?.id && profileImg) {
        const fileResponse = await fileUpload(
          profileImg,
          user.id,
          "USER_PROFILE"
        );
        if (fileResponse.url) {
          const payload = {
            ...user,
            profileImg: fileResponse.url,
          };
          const editResponse = await apis.editUser(payload, user.id);

          toast.dismiss();
          setIsLoading(false);
          handleModal();
          setForm({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            profileImg: "",
          });
          setProfileImg(null);
          refetch();
          setIsLoading(false);
          toast.success(
            editUser
              ? "User updated"
              : "User added and received the invitation email"
          );
        }
      } else {
        toast.dismiss();
        setIsLoading(false);
        handleModal();
        setForm({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          profileImg: "",
        });
        setProfileImg(null);
        refetch();
        setIsLoading(false);
        toast.success(
          editUser
            ? "User updated"
            : "User added and received the invitation email"
        );
      }
    } catch (err: any) {
      toast.error(
        editUser
          ? err.response.data.message
          : err.response.data.message || "Something went wrong"
      );
    }
  };

  useEffect(() => {
    if (
      form.firstName !== "" &&
      form.lastName !== "" &&
      form.email !== "" &&
      form.password !== "" &&
      !error.firstName.error &&
      !error.lastName.error &&
      !error.email.error &&
      !error.password.error &&
      !editUser
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [form, error]);
  useEffect(() => {
    if (isOpen) {
      if (editUser) {
        setForm({
          firstName: editUser.firstName,
          lastName: editUser.lastName,
          email: editUser.email,
          password: "",
          profileImg: editUser.profileImg,
        });
      } else {
        setForm({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          profileImg: "",
        });
        setProfileImg(null);
      }
    }
  }, [isOpen, editUser]);

  useEffect(
    () => {
      // validate password 1 uppercase, 1 lowercase, 1 number, 1 special character
      if (form.password !== "") {
        if (
          !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(form.password) ||
          form.password.length < 8
        ) {
          setError({
            ...error,
            password: {
              error: true,
              message:
                "Password must contain at least 8 characters, including 1 uppercase, 1 lowercase, 1 number and 1 special character",
            },
          });
        } else {
          setError({
            ...error,
            password: {
              error: false,
              message: "",
            },
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form.password]
  );
  const url = profileImg ? URL.createObjectURL(profileImg) : form.profileImg;

  return (
    <>
      <div
        className="fixed z-[50] overflow-auto top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] max-h-[90%] w-[570px]"
        style={{
          display: isOpen ? "block" : "none",
        }}
      >
        <div className="rounded-[16px] bg-[white] p-[1.75rem] z-[51]">
          <div className="flex justify-between mb-[1.5rem]">
            <h3 className="text-[1.75rem] font-[700]">Add new user</h3>
            <button
              onClick={() => {
                setError({
                  firstName: {
                    error: false,
                    message: "",
                  },
                  lastName: {
                    error: false,
                    message: "",
                  },
                  email: {
                    error: false,
                    message: "",
                  },
                  password: {
                    error: false,
                    message: "",
                  },
                });
                setForm({
                  firstName: "",
                  lastName: "",
                  email: "",
                  password: "",
                  profileImg: "",
                });
                setProfileImg(null);

                handleModal();
              }}
            >
              <img src={CloseIcon} alt="close" />
            </button>
          </div>
          <form className="grid gap-[1.875rem]" onSubmit={handleSubmit}>
            {editUser && (
              // <div className="border-solid border-[1px] border-[#ddd] p-4 rounded-[10px] flex gap-4">
              <div className="rounded-[10px] flex gap-4">
                <img
                  src={editUser.profileImg || UserIcon}
                  alt="user"
                  className="w-[5rem] h-[5rem] rounded-full"
                />
                {/* <button className="text-[0.875rem] text-[#433AE0]">
                  Change image
                </button> */}
              </div>
            )}
            <div className="justify-start items-center gap-3.5 flex">
              <input
                type="file"
                onChange={(e) => {
                  if (e.target.files?.[0]) {
                    setProfileImg(e.target.files[0]);
                  }
                }}
                id="profileImg"
                className="hidden"
                accept="image/png, image/jpeg"
                ref={(e) => (profileImgRef.current = e)}
              />
              {url ? (
                <ImageView
                  className="!w-24 !h-24 rounded-full object-cover"
                  src={url}
                  alt="user"
                  loading="lazy"
                  onClick={() => profileImgRef.current?.click()}
                />
              ) : (
                <div
                  onClick={() => profileImgRef.current?.click()}
                  className="uppercase w-24 h-24 rounded-full flex justify-center items-center text-3xl text-white bg-primary"
                >
                  <UploadIcon />
                </div>
              )}
              <div className="flex flex-col gap-[14px]">
                {(form.profileImg || profileImg) && (
                  <>
                    <button
                      onClick={() => profileImgRef.current?.click()}
                      className="text-primary text-base font-medium"
                      type="button"
                    >
                      Change photo
                    </button>
                    <button
                      onClick={() => {
                        setProfileImg(null);
                        setForm((prev) => ({ ...prev, profileImg: "" }));
                      }}
                      className="text-[#D12E2E] text-base font-medium"
                      type="button"
                    >
                      Remove photo
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="flex gap-4">
              <Input
                placeholder="First name"
                type="text"
                label="First name"
                id="firstName"
                onChange={handleChange}
                value={form.firstName}
                error={error.firstName.error}
                errorMessage={error.firstName.message}
              />
              <Input
                placeholder="Last name"
                type="text"
                label="Last name"
                id="lastName"
                onChange={handleChange}
                value={form.lastName}
                error={error.lastName.error}
                errorMessage={error.lastName.message}
              />
            </div>
            <Input
              placeholder="Email address"
              type="email"
              label="Email address"
              id="email"
              onChange={handleChange}
              value={form.email}
              error={error.email.error}
              errorMessage={error.email.message}
            />
            <Input
              placeholder="Password"
              type="password"
              label="Password"
              id="password"
              onChange={handleChange}
              error={error.password.error}
              value={form.password}
              errorMessage={error.password.message}
            />
            <Button
              type="submit"
              disabled={isDisabled && !editUser}
              isLoading={isLoading}
            >
              Save
            </Button>
          </form>
        </div>
      </div>
      {/* {isOpen && (
          <div
            className="fixed top-0 left-0 w-full z-[100] h-[100vh] opacity-[0.3] bg-[black]"
            onClick={handleModal}
          />
        )} */}
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full z-[49] h-[100vh] opacity-[0.3] bg-[black]"
          onClick={() => {
            setError({
              firstName: {
                error: false,
                message: "",
              },
              lastName: {
                error: false,
                message: "",
              },
              email: {
                error: false,
                message: "",
              },
              password: {
                error: false,
                message: "",
              },
            });
            setForm({
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              profileImg: "",
            });
            handleModal();
          }}
        />
      )}
    </>
  );
}
