export const DataRowSkeleton = () => {
  return (
    <div className="py-4 px-6 rounded-[6px] w-full flex items-center gap-4 border-b-[#E6E6E6] border-b-solid border-b-[1px] animate-pulse">
      <div className="w-1/5 flex items-center gap-2">
        <div className="w-[2.5rem] h-[2.5rem] rounded-full bg-gray-300" />
        <div className="w-3/4 h-4 bg-gray-300 rounded" />
      </div>
      <div className="w-1/3 h-4 bg-gray-300 rounded" />
      <div className="w-1/6 h-4 bg-gray-300 rounded" />
      <div className="w-1/5 h-4 bg-gray-300 rounded" />
      <div className="w-1/5 flex">
        <div className="w-16 h-4 bg-gray-300 rounded" />
      </div>
    </div>
  );
};
