import { AnimatePresence, motion } from "framer-motion";
import React, { ImgHTMLAttributes } from "react";

const framerSidebarBackground = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.25 } },
  transition: { duration: 0.5 },
};

export function ImageView({
  className,
  alt,
  containerClassName,
  ...props
}: ImgHTMLAttributes<HTMLImageElement> & { containerClassName?: string }) {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [isValidSrc, setIsValidSrc] = React.useState(!!props.src);
  return (
    <div className={`smooth-image-wrapper ${containerClassName || className}`}>
      {isValidSrc ? (
        <img
          className={`smooth-image ${className || ""} ${
            imageLoaded ? "opacity-100" : "opacity-0"
          }`}
          alt={alt}
          onLoad={() => setImageLoaded(true)}
          onError={() => setIsValidSrc(false)}
          {...props}
        />
      ) : (
        <div className="smooth-image image-error">
          <span>Image not available</span>
        </div>
      )}
      <AnimatePresence mode="wait" initial={false}>
        {!imageLoaded && (
          <motion.div {...framerSidebarBackground} className="smooth-preloader">
            <span {...props} className={`loader ${className || ""}`} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
