import { useEffect, useState } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import apis from "../../helpers/apis/apis";
import { useAuthContext } from "../../helpers/contexts/AuthContext";
import toast from "react-hot-toast";
import { ReactComponent as Logo } from "../../assets/logo-primary.svg";

export default function Login() {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  // validate form
  const [error, setError] = useState({
    email: {
      error: false,
      message: "",
    },
    password: {
      error: false,
      message: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const { login, getToken } = useAuthContext();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    apis
      .login({ email: form.email, password: form.password })
      .then((res) => {
        localStorage.setItem("token", res.accessToken);
        setIsLoading(false);
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        toast.error("Invalid email or password");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (form.email) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)) {
        setError({
          ...error,
          email: {
            error: true,
            message: "Please enter a valid email address",
          },
        });
        setIsDisabled(true);
      } else {
        setError({
          ...error,
          email: {
            error: false,
            message: "",
          },
        });
        setIsDisabled(false);
      }
    }
    if (form.password) {
      if (!form.password) {
        setError({
          ...error,
          password: {
            error: true,
            message: "Password is required",
          },
        });
        setIsDisabled(true);
      } else {
        setError({
          ...error,
          password: {
            error: false,
            message: "",
          },
        });
        setIsDisabled(false);
      }
    }
  }, [form.email]);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="absolute top-5 left-10">
        <div className="justify-center items-center flex relative">
          <Logo className="w-[175px] h-[55px]" />
          {/* <Ellipse className="absolute -left-[18px] right-0 h-[70px] w-[165px]" /> */}
        </div>
      </div>

      <div className="bg-[white] p-[2.5rem] w-fit rounded-[18px] mx-auto md:min-w-[520px] min-w-full">
        <h1 className="font-outfit font-[700] text-[1.75rem] mb-[2.5rem]">
          Sign in
        </h1>
        <form className="grid gap-4" onSubmit={handleSubmit}>
          <Input
            id="email"
            label="Email"
            type="email"
            placeholder="Enter your email"
            onChange={handleChange}
            error={error.email.error}
            errorMessage={error.email.message}
          />
          <Input
            id="password"
            label="Password"
            type="password"
            onChange={handleChange}
            placeholder="Enter your password"
          />
          <div className="w-full mt-4">
            <Button type="submit" isLoading={isLoading} disabled={isDisabled}>
              Sign in
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
